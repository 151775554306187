import {
  FinishedSessionWithResponsesFragment,
  ModulePublishedResponseFragment,
  ModuleResponseAndCompletionFragment,
  ModuleRevisionResponseFragment,
  PageWithPositionFragment,
  SeriesDiscountSettingsFragment,
  SeriesResponseFragment,
  SeriesWithModulesFragment,
} from '../operations'

export type LearningModuleType = ModulePublishedResponseFragment & {
  needs_subscription: boolean
}

export type LearningModuleWithSessionType =
  ModuleResponseAndCompletionFragment & {
    needs_subscription: boolean
  }

export type LearningSeriesType = SeriesResponseFragment & {
  applicable_discount: SeriesDiscountSettingsFragment
}
export type LearningSeriesWithModulesType = Omit<
  SeriesWithModulesFragment,
  'modules'
> & {
  applicable_discount: SeriesDiscountSettingsFragment
  modules: LearningModuleType[]
}

export type LearnerModuleReflections = { body: string }

export type LearningModuleRevisionType = ModuleRevisionResponseFragment
export type LearningModuleRevisionPageType = PageWithPositionFragment
export type LearningPage = PageWithPositionFragment & { _editorID?: string }
export type LearningSessionFinished = FinishedSessionWithResponsesFragment
export type LearningSessionFinishedWithoutResponses = Omit<
  LearningSessionFinished,
  'revision' | 'responses' | 'events'
>

export type LearningSessionScore = Pick<
  FinishedSessionWithResponsesFragment,
  'marks' | 'marks_available' | 'active' | 'finished_at' | 'status'
>
export type LearningModuleRevisionScore = Pick<
  LearningModuleRevisionType,
  'mcq_settings_pass_mark' | 'mcq_settings_pass_mark_type'
>

export enum LearningModuleEventType {
  start = 'start',
  answer = 'answer',
  finish = 'finish',
}
export enum McqSettingsPassMarkType {
  none = 'none',
  percentage = 'percentage',
  absolute = 'absolute',
}
